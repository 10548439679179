import React from "react"
import { Container } from "./Container"
import { Link } from "gatsby"
import { HeroImage } from "./HeroImage"
import { AnimationWrapper } from "./AnimationWrapper"
import { SplitLetters } from "./SplitLetters"

export const HeroSection = () => {
  const heroList = [
    "Swimming Pools & Fountains",
    "Sewage Treatment Plants",
    "Water Treatment Plants",
  ]

  return (
    <>
      <section className="h-screen relative mb-16">
        <HeroImage />
        <AnimationWrapper animationType="fadeOut" delay="0">
          <div className="absolute inset-0 bg-black h-screen text-white" />
        </AnimationWrapper>
        <div className="absolute inset-0 h-full text-white flex">
          <Container>
            <div
              className="text-white-90 font-sans mx-auto flex flex-col items-center relative"
              style={{
                top: "40%",
                transform: "translateY(-50%)",
              }}
            >
              <h1 className="text-center text-5xl font-semibold mb-6 highlight">
                Environmental <i>Solutions</i>
              </h1>
              <div className="text-white-90 mx-auto flex flex-col items-center font-sans">
                <p className="text-base font-light mb-4">
                  We design &amp; build
                </p>
                <div className="mb-6 flex flex-col items-center text-center text-xl text-white-85 uppercase leading-loose tracking-wider md:text-3xl  md:tracking-widest">
                  {heroList.map(item => (
                    <div key={item}>{item}</div>
                  ))}
                </div>
                <Link
                  to="#services-section"
                  className="px-2 py-2 text-sm border hover:border-body-lighter cursor-pointer hover:text-body-lighter"
                >
                  Know More
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  )
}
