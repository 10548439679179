import React from "react"
import { Header } from "../components/HeaderSection"
import { HeroSection } from "../components/HeroSection"
import { GetInTouch } from "../components/GetInTouch"
import { ClientList } from "../components/ClientList"
import { ServicesSection } from "../components/ServicesSection"
import { Footer } from "../components/Footer"
import SEO from "../components/seo"
import "../firebase"

const IndexPage = () => {
  return (
    <main className="bg-body-lightest">
      <SEO title="Home" />
      <Header noLogoText={true} />
      <HeroSection />
      <ServicesSection />
      <GetInTouch />
      <ClientList />
      <Footer />
    </main>
  )
}

export default IndexPage
