import React from "react"
import { Container } from "../components/Container"
import { Images } from "./Images"
import { Link } from "gatsby"

export const ClientList = () => {
  return (
    <section className="py-10 bg-white">
      <Container>
        <h1 className="home-header">Our Popular Clients</h1>
        <div className="flex items-center justify-end flex-wrap bg-white p-4">
          <Images
            className="flex flex-wrap bg-white p-4 justify-center"
            filterByText="topclients"
            imageClassName="w-32 scale_on_hover hover:shadow-lg"
          />
          <Link
            className=" px-4 py-2 font-semibold text-sm hover:bg-black-10 hover:text-black-75 sm:block"
            to="/clientele"
          >
            View More
          </Link>
        </div>
      </Container>
    </section>
  )
}
