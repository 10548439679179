import { initializeApp } from "firebase/app"
import { getAnalytics, isSupported } from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDF0bdCxs2GT0mOGvaPFBVsYok1Y0BNonM",
  authDomain: "environmental-solutions.firebaseapp.com",
  projectId: "environmental-solutions",
  storageBucket: "environmental-solutions.appspot.com",
  messagingSenderId: "68278784507",
  appId: "1:68278784507:web:211131f1a88e6c035a7b5e",
  measurementId: "G-C5LQG0ZNN3",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
let analytics
if (typeof window !== "undefined") analytics = getAnalytics(app)

export { analytics }
