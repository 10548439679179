import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container } from "../components/Container"
import { AnimationWrapper } from "../components/AnimationWrapper"
import { useInView } from "react-hook-inview"

export const GetInTouch = () => {
  const [animateContent, setAnimateContent] = useState(false)
  const [ref, isVisible] = useInView()
  useEffect(() => {
    if (isVisible && !animateContent) {
      setAnimateContent(true)
    }
  }, [animateContent, isVisible])

  const data = useStaticQuery(graphql`
    query {
      callImg: file(relativePath: { eq: "call.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mailImg: file(relativePath: { eq: "mail.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meetImg: file(relativePath: { eq: "meet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const contactData = [
    {
      h1: "make a call",
      fluid: data.callImg.childImageSharp.fluid,
      info: (
        <>
          <a className="pt-2 block" href="tel:+91484-2606058">
            0484-2606058
          </a>
          <a className="pb-2 block" href="tel:+919447030856">
            +919447030856
          </a>
        </>
      ),
    },
    {
      h1: "drop a mail",
      fluid: data.mailImg.childImageSharp.fluid,
      info: (
        <>
          <a className="py-2 block" href="mailto:mail4cleanwater@gmail.com">
            mail4cleanwater@gmail.com
          </a>
        </>
      ),
    },
    {
      h1: "meet in person",
      fluid: data.meetImg.childImageSharp.fluid,
      info: (
        <>
          <p className="py-2">
            <span className="font-bold">Environmental Solutions</span> <br />
            Parambayam, NH-47 , <br />
            Nedumbassery-683 585, <br />
            Aluva, Kochi,
            <br />
            Kerala,India
          </p>
        </>
      ),
    },
  ]
  return (
    <section className="relative mb-20" ref={ref}>
      <Container>
        <div className="w-full h-full">
          <h1 className="home-header">Get in touch</h1>
          <div className="flex flex-col justify-between items-center py-2 sm:flex-row sm:justify-center overflow-x-hidden">
            {animateContent &&
              contactData.map((contactType, i) => (
                <AnimationWrapper
                  animationType="slideToLeft"
                  key={contactType.h1}
                  delay={(i + 1) * 0.5}
                  className={`flex w-full my-2 bg-black-25 justify-center sm:${
                    i % 2 ? "flex-col" : "flex-col-reverse"
                  } sm:w-auto sm:my-0`}
                >
                  <div className="mr-auto w-1/4 flex items-center sm:w-56 h-56">
                    <Img fluid={contactType.fluid} className="w-full" />
                  </div>

                  <div className="w-3/4 h-56 p-4 text-center flex flex-col justify-center items-center sm:w-56">
                    <h1 className="card-header">{contactType.h1}</h1>
                    {contactType.info}
                  </div>
                </AnimationWrapper>
              ))}
          </div>
        </div>
      </Container>
    </section>
  )
}
