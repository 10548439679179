import React, { useState, useEffect } from "react"
import { useInView } from "react-hook-inview"
import { Container } from "./Container"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { AnimationWrapper } from "../components/AnimationWrapper"

export const ServicesSection = () => {
  const [animateCards, setAnimateCards] = useState(false)
  const [ref, isVisible] = useInView()
  useEffect(() => {
    if (isVisible && !animateCards) {
      setAnimateCards(true)
    }
  }, [animateCards, isVisible])

  const data = useStaticQuery(graphql`
    query {
      wtpImage: file(relativePath: { eq: "waterTreatment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stpImage: file(relativePath: { eq: "sewageTreatment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poolImage: file(relativePath: { eq: "pool.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      h1: "water treatment plants",
      img: data.wtpImage.childImageSharp.fluid,
      uri: "/water-treatment-plants",
      brief:
        "Enviable reputation in delivering the highest quality water treatment systems. Employed successfully for a variety of residential, commercial and industrial applications. ",
    },
    {
      h1: "sewage treatment plants",
      img: data.stpImage.childImageSharp.fluid,
      uri: "/sewage-treatment-plants",
      brief:
        "Proven Technology with many installations. Automatic Operation reduces the operating cost and provides ease for operation",
    },
    {
      h1: "swimming pools & fountains",
      img: data.poolImage.childImageSharp.fluid,
      uri: "/swimming-pools",
      brief: "Concrete / readymade pools, Jacuzzi and Fountains",
    },
  ]
  return (
    <section className="mb-16" ref={ref} id="services-section">
      <Container>
        <h1 className="home-header">Our Services</h1>
        <div className="flex flex-col">
          {animateCards &&
            services.map((x, i) => (
              <div className="p-2" key={i}>
                <Link
                  className={`mb-6 flex flex-col bg-white md:${
                    i % 2 ? "flex-row" : "flex-row-reverse"
                  } md:mx-8 lg:mx-16`}
                  to={x.uri}
                >
                  <div className="md:w-1/2">
                    <Img className="object-cover h-full" fluid={x.img} />
                  </div>

                  <div className="p-8 md:w-1/2">
                    <div>
                      <h1 className="card-header">{x.h1}</h1>
                      <p className="service-brief leading-normal text-lg mb-2">
                        {x.brief}
                      </p>
                    </div>
                    <p className="py-2 text-sm text-secondary underline self-end">
                      Know More
                    </p>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </Container>
    </section>
  )
}
